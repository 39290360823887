
ol,ul{
    list-style:none
}

.dialpad .dials{
    background:#f5f5f5;
 
}

.dialpad .dials:after{
    clear:both
}
.dialpad .dials .digits{
    float:left;
    width:20.33%;
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 5px;
}
.dialpad .dials .digits p{
    background: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor:pointer;
    text-align: center;
    margin: 0px; 

}
.dialpad .dials .digits p strong{
    font-size:20px;
    color:black;
    /* margin-top: 15px; */
    position: inherit;
    /* margin-left: 8; */
    font-weight:600;
}
.compact .dials .digits p .divStyle{
    text-transform:uppercase;
    color:black;
    /* margin-top: 40px; */
    /* position: fixed; */
    /* margin-left: 4px; */
    font-size: 10px;
    text-align: center;
}
